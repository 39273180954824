// Vendor files


require('./vendor/jquery-1.11.1.min');

var $ = window.jQuery = window.$ = require('./vendor/jquery-1.11.1.min');

var $$_ = window.$$_ = require('./shared/core'); 

require('./vendor/slick'); 

require('./vendor/parallax.min');

var Isotope = require('../_gulp/node_modules/isotope-layout');

// include your scripts here

// require('./shared/textarea-autosize'); 
// require('./shared/img'); 
// require('./shared/map'); 
// require('./shared/parallax'); 
// require('./shared/select'); 
// require('./shared/search'); 

// expose your functions to the global scope for testing
var mxm = {},
_viewing;

// init some things
$(function($){
	$('.header').click(function(){
		// console.log('yeah!')
		$('body').toggleClass('sidebar_open')
	});

	// console.log('yeah!');


	//  ad88888ba  88 88          88
	// d8"     "8b 88 ""          88
	// Y8,         88             88
	// `Y8aaaaa,   88 88  ,adPPYb,88  ,adPPYba, 8b,dPPYba,
	//   `"""""8b, 88 88 a8"    `Y88 a8P_____88 88P'   "Y8
	//         `8b 88 88 8b       88 8PP""""""" 88
	// Y8a     a8P 88 88 "8a,   ,d88 "8b,   ,aa 88
	//  "Y88888P"  88 88  `"8bbdP"Y8  `"Ybbd8"' 88
	
	var _slickSettings = {
	  dots: true,
	  infinite: true,
	};

	if (window.location.hash.indexOf('#haendler') >= 0){
		$('.header_nav').find('a').first().removeClass('current').next().addClass('current')
	}

	if ($(window).width() > 768){
		window.onbeforeunload = function () {
		  window.scrollTo(0, 0);
		}
	}

	$('.header_logo').on('click', function(){
		// console.log('yeah!')
		$(this).siblings('.header_nav').find('a').first().click();
	});

	$('.header_nav a').on('click', function(){
		$('body').removeClass('headerOpen');
		$('.header_hamburger').removeClass('fa-times').addClass('fa-bars');
	})

	$('.header_hamburger').on('click', function(){
		$('body').toggleClass('headerOpen');

		if ($('body').hasClass('headerOpen')){
			$(this).removeClass('fa-bars').addClass('fa-times')
		}else{
			$(this).removeClass('fa-times').addClass('fa-bars')
		}
	});



	var $window = $(window),
	$body = $('body'),
	$header_nav = $('.header_nav'),
	showing_vendor = false,
	is_summer = $('body').hasClass('summer'),
	_offset = 0;

	_sectionPos = [
		{section: 'weihnachtsmarkt'},
		{section: 'haendler'},
		{section: 'stellen'},
		{section: 'kontakt'},
	],

	scrollTo = function(where){
		var target = $(where).offset().top;

		if ($window.width() > 768){
			_offset = 35;
		}

		$('html,body').animate({
			scrollTop: (target) - (65 + _offset)
		}, 1000);
	},

	getSectionPos = function(){
		if (!is_summer) {

			if ($window.width() < 768){
				$('.index_hero').css('height', $window.height());
			}else{
				$('.index_hero').css('height', '');			
			}
			
			$('a.content').each(function(e){
				var $that = $(this);

				// _sectionPos[e].location = $that.offset().top - $window.scrollTop();
				_sectionPos[e].location = $that.offset().top;// - $window.scrollTop();
			});
		}

		// console.log(_sectionPos);
	};

	setTimeout(getSectionPos, 1000);
	// getSectionPos();

	$window.resize(function(){
		getSectionPos();
	});

	var navClick = function(e, _this){
		e.preventDefault();
		var $that = $(_this);

		$that.addClass('current').siblings().removeClass()

		scrollTo('a.content[id="' + $that.attr('href').split('#')[1] + '"]');
	}

	$header_nav.find('a').on('click', function(e){
		navClick(e, this);

		$('.vendor_hero_back').click();

		// window.location.hash = $that.attr('href').split('#')[1]; 
	});

	$('.footer_nav').find('a').on('click', function(e){
		navClick(e, this);
		// window.
	});

	$('.index_hero').on('click', function(){
		scrollTo('a.content#weihnachtsmarkt');		
	});





	if ($body.hasClass('page_index') ){

		var _height = $(window).outerHeight();

  	$window.scroll(function(e){
  		var $scrollTop = $(this).scrollTop();

  		// console.log($scrollTop)

  		if (!showing_vendor){
	  		if ($scrollTop > ((_height / 2)) - 100){
		  		$body.addClass('show_header');
	  		}else{
		  		$body.removeClass('show_header');
		  		// alert('CLOSE ' + showing_vendor)	
	  		}
  		}

  		for (var i = 0; i < _sectionPos.length; i++){

  		// 	console.log(
  		// 		(($scrollTop) ),
  		// 		(_sectionPos[i].location - 49),
  		// 		(($scrollTop) > _sectionPos[i].location - 52)
				// )

				if ($window.width() > 768){
					_offset = 35;
				}

  			if (
					(($scrollTop) > _sectionPos[i].location - (67 + _offset))
  			){
					$header_nav.find('a[href="#' + _sectionPos[i].section + '"]').addClass('current')
  					.siblings().removeClass('current')
  			}else{
  			}
  		}
  	});


  	// var parallax = document.querySelectorAll(".parallax"),
  	// 	parallax_slow = document.querySelectorAll(".parallax_slow"),
   //    speed = 0.5;

  	//   window.onscroll = function(){

  	//     [].slice.call(parallax).forEach(function(el,i){

  	//       var windowYOffset = window.pageYOffset,
  	//           elBackgrounPos = "50% calc(5vh + " + (windowYOffset * speed) + "px)";

  	//       el.style.backgroundPosition = elBackgrounPos;

  	//     });

	  //   	var _teaserCalculation = (
	  //   		$('.index_hero_teaser').offset().top - 
	  //   		(
  	//     		( $(window).height() - $('.index_hero_teaser').height() ) / 2
	  //   		)
	  //   	);

  	//     if (
  	//     	window.pageYOffset > _teaserCalculation
	  //   	){
	  // 	    [].slice.call(parallax_slow).forEach(function(el,i){

	  // 	    	var card_travel = window.pageYOffset - _teaserCalculation

	  // 	      var windowYOffset = window.pageYOffset,
	  // 	          elTransform = 'translateY(' + (card_travel * .5) + 'px)';

	  // 	      el.style.transform = elTransform;

	  // 	    });
  	    	
  	//     }else{
  	//     	[].slice.call(parallax_slow).forEach(function(el,i){
   //  		    el.style.transform = 'translateY(0px)';
	  //   		});
  	//     }

  	//   };

	}


	// 88b           d88                       88
	// 888b         d888                       88                    ,d
	// 88`8b       d8'88                       88                    88
	// 88 `8b     d8' 88 ,adPPYYba, 8b,dPPYba, 88   ,d8  ,adPPYba, MM88MMM
	// 88  `8b   d8'  88 ""     `Y8 88P'   "Y8 88 ,a8"  a8P_____88   88
	// 88   `8b d8'   88 ,adPPPPP88 88         8888[    8PP"""""""   88
	// 88    `888'    88 88,    ,88 88         88`"Yba, "8b,   ,aa   88,
	// 88     `8'     88 `"8bbdP"Y8 88         88   `Y8a `"Ybbd8"'   "Y888
	
	// $('.market_section .market_title').on('click', function(){
	// 	var $that = $(this);

	// 	var $ms = $that.parents('.market_section');

	// 	$ms.toggleClass('closed');

	// 	$ms.siblings('.market_section').addClass('closed');

	// 	// $that[0].scrollIntoView({block: "start", inline: "nearest"});

	// 	$$_.scrollToHere($that);

	// 	if ($ms.hasClass('closed')){
	// 		$ms.find('.slider').slick('unslick');
	// 	}else{
	// 		$ms.find('.slider').slick(_slickSettings);
	// 	}
		
	// });

	// $('.market_text a').filter(function() {
	//     return this.hostname != window.location.hostname;
	// }).attr('target', '_blank');




	//   ,ad8888ba,             88          88
	//  d8"'    `"8b            ""          88
	// d8'                                  88
	// 88            8b,dPPYba, 88  ,adPPYb,88
	// 88      88888 88P'   "Y8 88 a8"    `Y88
	// Y8,        88 88         88 8b       88
	//  Y8a.    .a88 88         88 "8a,   ,d88
	//   `"Y88888P"  88         88  `"8bbdP"Y8
	
	

	

	

	var loadGrid = function(content){

		// if (typeof content !== 'undefined'){
			// $vendor_grid.find('.vendor_grid_interior_floater').append(content);

			$vendor_grid.find('.vendor_content').each(function(){
				var $that = $(this);

				var vendorName = $that.find('.vendor_title h1').text();

				$that.prev('.vendor_tile').find('.tile_text_title').html(vendorName);
			})
		// }


		var isotope_options = {
			sortBy : 'random',
			itemSelector: '.tile',
			layoutMode: 'fitRows'
		}

		var iso = new Isotope( '.vendor_grid_interior_floater', isotope_options);

		$('.vendor_grid_filter_option').on('click', function(e){
			var $that = $(this);

			var value = $that.data('value');

			if (value === '*'){
				value = '*'
				// window.location.hash = '';
			}else{
				value = '.day_' + $that.data('value');
				// window.location.hash = $that.data('value');
			}

			// console.log(value)

			$that.siblings().removeClass('selected');

			$that.addClass('selected');

			iso.arrange({
				filter: value
			})
			iso.arrange();

		});

		$('.header_nav').on('click', '.internal', function(e){
			// console.log('yeah!')
			if ($('body').hasClass('page_index')){
				// e.preventDefault();
				$('.vendor_content[data-slug="' + _viewing + '"] .vendor_hero_back').click();

			}
		});

		$('.vendor_tile').on('click', function(){
			showing_vendor = true;

			var $that = $(this);

			var _slug = $that.data('target'),
			$content = $('.vendor_content[data-slug="' + _slug + '"]');
			// console.log(_slug);

			_viewing = _slug;

			$body.addClass('showing_vendor');

			setTimeout(function(){
				$body.addClass('show_header');
			}, 500)


			window.location.hash = _slug;

			heroImageUrl = $content.find('.vendor_hero_background').data('src');

			$content.find('.vendor_hero_background').css('background-image', 'url(' + heroImageUrl + ')');

			$content.find('.vendor_gallery_image').each(function(){
				var $$that = $(this),
				_url = $$that.data('src');

				// console.log(_url)

				$$that.css('background-image', 'url(' + _url + ')');
			});

			// console.log(
			// 	$content,
			// 	$content.find('.vendor_gallery'),
			// 	$content.find('.vendor_gallery').children(),
			// 	$content.find('.vendor_gallery').children().length
			// )

			if ($content.find('.vendor_gallery').children().length){
				$content.find('.vendor_gallery').slick(_slickSettings);

				$content.find('.vendor_gallery_image').on('click', function(){
					$content.find('.vendor_gallery').slick('slickNext');
				});				
			}


			$content.removeClass('closed');

			setTimeout(function(){
				$(window).resize();
			}, 100)

		});

		$('.vendor_hero_back, .vendor_footer').on('click', function(){
			showing_vendor = false;

			var $that = $(this),
			$vendor_content = $that.parents('.vendor_content');
			
			_viewing = '';

			$vendor_content.scrollTop(0);

			$vendor_content.addClass('closed');

			window.location.hash = '';

			$body.removeClass('showing_vendor');

			if ($vendor_content.find('.vendor_gallery').hasClass('slick-initialized')){
				$vendor_content.find('.vendor_gallery').slick('unslick');
			}

			document.getElementById('haendler').scrollIntoView();

			setTimeout(function(){
				$vendor_content.scrollTop(0);
			}, 500);
		});

		if (
			window.location.hash && window.location.hash !== 'haendler' && 
			$('.vendor_tile[data-target="' + window.location.hash.split('#')[1] + '"]').hasClass('aktiv')
		){

			showing_vendor = true;

			// console.log('yeah!', $('.vendor_tile[data-target="' + window.location.hash.split('#')[1] + '"]'))

			$('.vendor_tile[data-target="' + window.location.hash.split('#')[1] + '"]').click();

			// $('body').addClass('show_header');
			$body.addClass('show_header');

			setTimeout(function(){
				$body.addClass('show_header');
			}, 1000)
			// isotope_options['filter'] = '.day_' + window.location.hash.split('#')[1];

			// console.log($('.vendor_grid_filter div[data-value="' + window.location.hash.split('#')[1] + '"]'))

			// $('.vendor_grid_filter div[data-value="' + window.location.hash.split('#')[1] + '"]').addClass('selected')
		}

		setTimeout(function(){
			if (typeof failsafe !== 'undefined'){
				clearInterval(failsafe);
			}
		}, 100);


		// things to do on completion
	};

	var $vendor_grid = $('.vendor_grid');

	if ($vendor_grid.length && !is_summer){
	// 	$.ajax({
	// 		url: 'http://' + window.location.host + "/marit/haendler.php",
	// 	}).done( function(_content){
			// loadGrid(_content)
			loadGrid()
	// 	})
	}











	//////////////
});




// template for new JS files, a la browserify
$(function($){

});



















